import React from 'react';

import { Link } from '..';
import Author from './author';
import readingTime from '../../utils/reading-time';
import useHttps from '../../utils/use-https';

/* eslint-disable camelcase */
interface PostCardProps {
  title: string;
  slug: string;
  html: string;
  feature_image?: string;
  custom_excerpt?: string;
  primary_author?: any;
  primary_tag?: any;
}

const PostCard: React.FC<PostCardProps> = ({
  title,
  slug,
  feature_image,
  custom_excerpt,
  primary_author,
  primary_tag,
  html,
}) => (
  <li className="blog-post-card">
    <Link to={`/blog/${slug}`} className="blog-post-link">
      <div className="blog-post-card-content">
        {feature_image ? (
          <div
            className="post-feature-img"
            style={{ backgroundImage: `url(${useHttps(feature_image)})` }}
          />
        ) : null}
        <article className="post-info">
          <div className="post-info-header">
            {primary_tag ? (
              <span className="post-tag">
                <strong>{primary_tag.name}</strong>
              </span>
            ) : null}
            <span className="post-read-time">{readingTime(html)} min read</span>
          </div>
          <h2 className="post-title">{title}</h2>
          <p className="post-excerpt">{custom_excerpt}</p>
          {primary_author ? <Author {...primary_author} /> : null}
        </article>
      </div>
    </Link>
  </li>
);
/* eslint-enable camelcase */

export default PostCard;
