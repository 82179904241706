import React from 'react';
import { graphql } from 'gatsby';

import { BlogLayout, PostCard, SubscribeBanner } from '../../components/blog';

const TagPage: React.FC<{ data: any }> = ({ data }) => {
  const tag = data && data.ghostTag;
  const posts =
    (data && data.allGhostPost && data.allGhostPost.edges).map(p => p.node) ||
    [];

  const postList = posts.map(post => <PostCard key={post.id} {...post} />);
  postList.splice(2, 0, <SubscribeBanner key="blog-main-subscribe" />);

  return (
    <BlogLayout title={tag.name} seoPrefix="Fidel API in Focus - ">
      <div className="centered">
        <h2 className="post-collection-title">{tag.name}</h2>
        <p className="secondary-text">
          A collection of {tag.postCount} post{tag.postCount > 1 ? 's' : ''}
        </p>
      </div>
      <ul className="blog-post-list" data-testid="blog-post-list">
        {postList}
      </ul>
    </BlogLayout>
  );
};

export const pageQuery = graphql`
  query GhostTagQuery($slug: String!) {
    ghostTag(slug: { eq: $slug }) {
      slug
      name
      postCount
    }
    allGhostPost(
      sort: { order: DESC, fields: [published_at] }
      filter: { tags: { elemMatch: { slug: { eq: $slug } } } }
    ) {
      edges {
        node {
          ...BlogPost
        }
      }
    }
  }
`;

export default TagPage;
