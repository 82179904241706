import React from 'react';
import { Link } from 'gatsby';

const Pagination: React.FC<{ pageContext: any }> = ({ pageContext }) => {
  const { previousPagePath, nextPagePath, humanPageNumber, numberOfPages } =
    pageContext;

  return (
    <nav className="pagination">
      {previousPagePath && (
        <Link to={previousPagePath} rel="prev" className="pagination-link">
          Previous
        </Link>
      )}
      {numberOfPages > 1 && <>{pagesLinks(numberOfPages, humanPageNumber)}</>}
      {nextPagePath && (
        <Link to={nextPagePath} rel="next" className="pagination-link">
          Next
        </Link>
      )}
    </nav>
  );
};

function pagesLinks(numberOfPages: number, current: number) {
  const pages: React.ReactNode[] = [];
  for (let page = 1; page < numberOfPages + 1; page++) {
    const path = page === 1 ? '/blog' : `/blog/page/${page}`;
    const className = `pagination-link${
      page === current ? ' pagination-current' : ''
    }`;
    pages.push(
      <Link key={page} to={path} className={className}>
        {page}
      </Link>,
    );
  }

  return pages;
}

export default Pagination;
