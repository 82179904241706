import React from 'react';
import useHttps from '../../utils/use-https';

/* eslint-disable camelcase */
interface AuthorProps {
  name: string;
  bio: string;
  withImage?: boolean;
  profile_image?: string;
}

const Author: React.FC<AuthorProps> = ({
  name,
  bio,
  withImage,
  profile_image,
}) => (
  <div className="post-author">
    {withImage ? (
      <>
        {profile_image && (
          <img className="post-author-img" src={useHttps(profile_image)} />
        )}
        <div className="post-author-info">
          <div
            style={{
              textTransform: 'uppercase',
              fontWeight: 600,
              marginBottom: '6px',
            }}
          >
            <span style={{ letterSpacing: '1.8px' }}>{name}</span>
          </div>
          <span className="post-author-bio">{bio}</span>
        </div>
      </>
    ) : (
      <div className="post-author-info">
        <strong>By {name}</strong> –{' '}
        <span className="post-author-bio nowrap">{bio}</span>
      </div>
    )}
  </div>
);
/* eslint-enable camelcase */

export default Author;
