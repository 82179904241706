import React from 'react';

import RelatedPostCard from './related-post-card';
import MobileLineBreak from '../mobile-line-break';

const RelatedPosts: React.FC<{ posts: any[] }> = ({ posts }) => (
  <>
    <h2 className="related-posts-title">
      <strong>Keep reading</strong> — <MobileLineBreak />
      Popular articles
    </h2>
    <div className="three-columns related-posts" data-testid="recent-blogposts">
      {posts.map(post => (
        <RelatedPostCard key={post.slug} {...post} />
      ))}
    </div>
  </>
);

export default RelatedPosts;
