import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { Offscreen } from '../styled';
import { Link, SubscribeForm } from '..';

const BlogSidebar: React.FC = () => {
  const { ghostSettings } = useStaticQuery(query);

  return (
    <div className="blog-sidebar">
      <Link to="/blog">
        <div className="blog-title-box">
          <h1>
            <Offscreen>Fidel API </Offscreen>In
            <br /> Focus
          </h1>
        </div>
      </Link>
      <nav className="blog-sidebar-nav">
        <ul className="nav-list">
          {ghostSettings.navigation.map(nav => (
            <li key={nav.url}>
              <Link to={toInternalLink(nav.url)} className="blog-sidebar-link">
                {nav.label}
              </Link>
            </li>
          ))}
        </ul>
        <BlogSubscribe />
      </nav>
    </div>
  );
};

function toInternalLink(url: string) {
  const internalUrls = ['https://fidel.uk', 'https://fidel.us'];

  return internalUrls.includes(url) ? url.substring(16) : url;
}

function BlogSubscribe() {
  const [formVisible, setFormVisible] = useState(false);

  return (
    <div
      onMouseEnter={() => {
        setFormVisible(true);
      }}
      className="blog-sidebar-subscribe"
      data-testid="sidebar-subscribe"
    >
      <div>Subscribe</div>
      {formVisible && (
        <SubscribeForm
          className="blog-sidebar-subscribe-form"
          size="small"
          btnText="→"
          id="Subscribe-WS-Blog_sidebar"
        />
      )}
    </div>
  );
}

export const query = graphql`
  query {
    ghostSettings {
      navigation {
        label
        url
      }
    }
  }
`;

export default BlogSidebar;
