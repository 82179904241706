import React from 'react';

import { SubscribeForm } from '..';

const SubscribeBanner: React.FC = () => (
  <div className="subscribe-banner">
    <h3 className="subscribe-banner-title">Subscribe to our newsletter</h3>
    <SubscribeForm
      className="blog-list-subscribe-form"
      btnText="→"
      id="Subscribe-WS_Blog"
    />
  </div>
);

export default SubscribeBanner;
