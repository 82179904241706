import React from 'react';

import { Link } from '..';
import Author from './author';
import readingTime from '../../utils/reading-time';
import useHttps from '../../utils/use-https';

/* eslint-disable camelcase */
interface RelatedPostCardProps {
  title: string;
  slug: string;
  html: string;
  feature_image: string;
  primary_author: any;
  primary_tag?: any;
  twitter_image?: string;
}

const RelatedPostCard: React.FC<RelatedPostCardProps> = ({
  title,
  slug,
  html,
  feature_image,
  primary_author,
  primary_tag,
  twitter_image,
}) => (
  <Link className="related-post-card" to={`/blog/${slug}`}>
    <img
      src={useHttps(twitter_image || feature_image)}
      srcSet={useHttps(twitter_image || feature_image)}
      alt={title}
      className="blogpost-link-image"
    />
    <article>
      <header>
        {primary_tag && <span className="post-tag">{primary_tag.name}</span>}
        <span className="post-read-time">{readingTime(html)} min read</span>
      </header>
      <h3 className="post-link-title">{title}</h3>
      <Author name={primary_author.name} bio={primary_author.bio} />
    </article>
  </Link>
);
/* eslint-enable */

export default RelatedPostCard;
