import React from 'react';
import { Section } from '..';
import { Layout, SEO } from '../../layout';

import Sidebar from './sidebar';

interface BlogLayoutProps {
  children: React.ReactNode;
  title: string;
  seoPrefix?: string;
  seoTitle?: string;
  seoImage?: string;
  seoDescription?: string;
}

const BlogLayout: React.FC<BlogLayoutProps> = ({
  children,
  title,
  seoPrefix,
  seoTitle,
  seoImage,
  seoDescription,
}) => (
  <Layout>
    <SEO
      title={title}
      prefix={seoPrefix}
      image={seoImage}
      description={seoDescription}
      socialTitle={seoTitle}
    />
    <Section className="blog-section">
      <Sidebar />
      <div className="blog-main">{children}</div>
    </Section>
  </Layout>
);

export default BlogLayout;
