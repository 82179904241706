import React from 'react';

import EmailIcon from './assets/email.svg';
import LinkedinIcon from './assets/linkedin.svg';
import TwitterIcon from './assets/twitter.svg';
import { Offscreen } from '../styled';

interface ShareIconsProps {
  slug: string;
  title: string;
}

interface SocialLink {
  icon: React.ReactNode;
  label: string;
  url: string;
}

const ShareIcons: React.FC<ShareIconsProps> = ({ slug, title }) => {
  const baseUrl = 'https://fidel.uk/blog/';
  const fbLogo = require('./assets/facebook.png').default;

  const socialLinks: SocialLink[] = [
    {
      icon: <TwitterIcon />,
      label: 'Twitter',
      url: `https://twitter.com/intent/tweet?text=${title}&url=${baseUrl}${slug}`,
    },
    {
      icon: <img src={fbLogo} />,
      label: 'Facebook',
      url: `https://facebook.com/sharer.php?u=${baseUrl}${slug}`,
    },
    {
      icon: <LinkedinIcon />,
      label: 'LinkedIn',
      url: `https://www.linkedin.com/sharing/share-offsite/?url=${baseUrl}${slug}`,
    },
    {
      icon: <EmailIcon />,
      label: 'Email',
      url: `mailto:?Subject=Fidel API in Focus: ${title}&Body=${baseUrl}${slug}`,
    },
  ];

  function share(url) {
    window.open(`${url}`, 'pop', 'width=600, height=400, scrollbars=no');
  }

  return (
    <span className="share-icons">
      <small className="share-label">Share</small>
      <span className="share-buttons-container">
        {socialLinks.map(({ icon, label, url }: SocialLink) =>
          label === 'Email' ? (
            <a className="share-button" key={label} href={url}>
              {icon}
            </a>
          ) : (
            <button
              key={label}
              type="button"
              className="share-button"
              onClick={() => {
                share(url);
              }}
              data-testid={`share-btn-${label}`}
            >
              {icon}
              <Offscreen>{label}</Offscreen>
            </button>
          ),
        )}
      </span>
    </span>
  );
};

export default ShareIcons;
